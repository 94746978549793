import React, { createContext, useContext, useState, PropsWithChildren, Fragment } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { cva, VariantProps } from 'class-variance-authority'
import { Button, cn, Icon, Typography, TypographyProps } from '@design-system'

const DialogContext = createContext<any>(null)

const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('Dialog components must be used within a DialogProvider')
  }
  return context
}

const rootVariants = cva(
  'bg-white dark:bg-gray-100 dark:border-gray-90 shadow-lg flex flex-col',
  {
    variants: {
      side: {
        center:
          'p-6 w-full mt-auto md:mt-0 rounded-t-xl md:rounded-xl',
        top: 'mb-auto border-b w-full !max-w-full rounded-b-xl p-2 gap-4 data-[headlessui-state=closed]:slide-out-to-top data-[headlessui-state=open]:slide-in-from-top',
        bottom:
          'mt-auto border-t w-full !max-w-full rounded-t-xl p-2 gap-4 data-[headlessui-state=closed]:slide-out-to-bottom data-[headlessui-state=open]:slide-in-from-bottom',
        left: 'inset-y-0 p-2 gap-4 fixed left-0 h-full w-80 max-w-[80vw] border-r data-[headlessui-state=closed]:slide-out-to-left data-[headlessui-state=open]:slide-in-from-left',
        right:
          'inset-y-0 p-6 gap-4 fixed right-0 h-full w-80 max-w-[80vw] border-l data-[headlessui-state=closed]:slide-out-to-right data-[headlessui-state=open]:slide-in-from-right',
      },
      size: {
        xs: 'sm:max-w-md',
        sm: 'sm:max-w-screen-sm',
        md: 'sm:max-w-screen-md',
        lg: 'sm:max-w-screen-lg',
      }
    },
    defaultVariants: {
      side: 'center',
      size: 'md'
    },
  }
)

export interface DialogRootProps extends PropsWithChildren {
  open?: boolean
  onClose?: () => void
}

const Root = ({ open, onClose, children }: DialogRootProps) => {
  const [isOpen, setIsOpen] = useState(open ?? false)

  const openDialog = () => setIsOpen(true)
  const closeDialog = () => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <DialogContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  )
}

interface DialogContentProps extends VariantProps<typeof rootVariants>, PropsWithChildren {
  className?: string
}

const Content = ({ side, size, className, children }: DialogContentProps) => {
  const { isOpen, closeDialog } = useDialog()

  const enterAnimation = {
    center: "duration-300 opacity-0 scale-95",
    top: "duration-300 opacity-0 animate-in slide-in-from-top",
    bottom: "duration-300 opacity-0 animate-in slide-in-from-bottom",
    left: "duration-300 opacity-0 animate-in slide-in-from-left",
    right: "duration-300 opacity-0 animate-in slide-in-from-right",
  }[side || 'center']

  const leaveAnimation = {
    center: "ease-in duration-200 opacity-100 scale-100",
    top: "ease-in duration-200 opacity-100 translate-y-0",
    bottom: "ease-in duration-200 opacity-100 -translate-y-0",
    left: "ease-in duration-200 opacity-100 translate-x-0",
    right: "ease-in duration-200 opacity-100 -translate-x-0",
  }[side || 'center']

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog open={isOpen} onClose={() => { }} className="focus:outline-none relative z-50">
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex w-full min-h-full items-center justify-center pt-6 md:pt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" onClick={closeDialog} />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter={enterAnimation}
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave={leaveAnimation}
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessDialog.Panel className={cn(rootVariants({ side, size, className }))}>
                {children}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}


interface DialogHeaderProps extends VariantProps<typeof headerVariants>, React.HTMLAttributes<HTMLDivElement> { }

const headerVariants = cva(
  'grid grid-cols-[1fr_auto] [grid-template-areas:"title_close""description_description"]',
  {
    variants: {
      variant: {
        ghost: '',
        outlined: '-mx-6 px-6 pb-4 border-b border-gray-20 dark:border-gray-90',
      },
    },
    defaultVariants: {
      variant: 'ghost',
    },
  }
)

const Header = ({ children, className, variant }: DialogHeaderProps) => (
  <header className={cn(headerVariants({ variant, className }))}>
    {children}
  </header>
)

const Title = ({ children, ...props }: TypographyProps) => (
  <Typography {...props} variant="subheadline" weight="bold" className="grow [grid-area:title] self-center">
    {children}
  </Typography>
)

const Description = ({ children, ...props }: TypographyProps) => (
  <Typography {...props} variant="body" className="[grid-area:description] mt-2" color="secondary">
    {children}
  </Typography>
)

interface DialogBodyProps extends PropsWithChildren {
  className?: string
}

const Body = ({ children, className }: DialogBodyProps) => (
  <div className={cn("flex flex-col gap-4 mt-2", className)}>
    {children}
  </div>
)

interface DialogFooterProps extends PropsWithChildren {
  className?: string
}

const Footer = ({ children, className }: DialogFooterProps) => (
  <footer className={cn("flex flex-col-reverse md:flex-row md:justify-end gap-4 mt-4", className)}>
    {children}
  </footer>
)

const Slot = ({ children, ...props }) => {
  const Child = React.Children.only(children)
  return React.cloneElement(Child, props)
}

const Trigger = ({ children, asChild = false, ...props }) => {
  const { openDialog } = useDialog()
  const Comp = asChild ? Slot : Button

  return (
    <Comp onClick={openDialog} {...props}>
      {children}
    </Comp>
  )
}

interface DialogCloseProps extends PropsWithChildren {
  asChild?: boolean
}

const Close = ({ children, asChild }: DialogCloseProps) => {
  const { closeDialog } = useDialog()

  if (asChild) {
    return (
      <Slot onClick={closeDialog} className="[grid-area:close]">
        {children}
      </Slot>
    )
  }

  return (
    <Button size="xs" variant="ghost" onClick={closeDialog} className="[grid-area:close]">
      <Icon name="cross" size="xs" />
    </Button>
  )
}

export const Dialog = Object.assign(Root, {
  Content,
  Header,
  Title,
  Description,
  Body,
  Footer,
  Trigger,
  Close,
  useDialog,
})
