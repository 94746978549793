import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '@design-system'

const Root = PopoverPrimitive.Root

const Trigger = PopoverPrimitive.Trigger

const Portal = PopoverPrimitive.Portal

export type PopoverContentType = typeof PopoverPrimitive.Content

const Content = React.forwardRef<
  React.ElementRef<PopoverContentType>,
  React.ComponentPropsWithoutRef<PopoverContentType> & {
    container?: HTMLElement | null
  }
>(({ className, align = "start", sideOffset = 4, alignOffset = 0, container, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    alignOffset={alignOffset}
    className={cn(
      "z-50 rounded-md border-gray-30 dark:border-gray-100 bg-white dark:bg-gray-100 p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))
Content.displayName = PopoverPrimitive.Content.displayName

export const unstable_Popover = Object.assign(Root, { Trigger, Content, Portal })
