interface UsePaginationProps {
  totalPages: number
  currentPage: number
}

/**
 * Computes an array of page numbers to display in pagination.
 *
 * @param totalPages - Total number of pages available.
 * @param currentPage - Currently active page.
 * @returns An ordered list of page numbers for display.
 */
export const usePagination = ({ totalPages, currentPage }: UsePaginationProps): number[] => {
  const MAX_VISIBLE_PAGES = 5
  if (totalPages <= 0) return []
  if (totalPages === 1) return [1]

  const pages = new Set<number>([1, totalPages])

  const middleOffset = Math.floor(MAX_VISIBLE_PAGES / 2)

  const calculateVisiblePageRange = (): [number, number] => {
    const isNearStart = currentPage <= middleOffset + 1
    const isNearEnd = currentPage >= totalPages - middleOffset

    if (isNearStart) {
      const endPage = Math.min(MAX_VISIBLE_PAGES + 1, totalPages - 1)
      return [2, endPage]
    }

    if (isNearEnd) {
      const startPage = Math.max(totalPages - MAX_VISIBLE_PAGES, 2)
      return [startPage, totalPages - 1]
    }

    return [currentPage - middleOffset, currentPage + middleOffset]
  }

  const [visibleStart, visibleEnd] = calculateVisiblePageRange()
  for (let page = visibleStart; page <= visibleEnd; page++) {
    pages.add(page)
  }

  return Array.from(pages).sort((a, b) => a - b)
}
