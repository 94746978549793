import React, { useEffect } from 'react'
import { FetcherFormProps as OriginalFetcherFormProps, useFetcher as originalUseFetcher, useNavigate } from 'react-router'
import { toast } from 'sonner'
import useConfirmModal from '@hooks/useConfirmModal'

/**
 * Custom hook that wraps the original useFetcher hook from react-router
 * and adds functionality for displaying toasts, a confirmation modal, and
 * handling redirects.
 *
 * @param {any} [params] - Optional parameters to pass to the original useFetcher hook.
 * @returns {object} The fetcher object returned by the original useFetcher hook.
 */
export function useFetcher(params = undefined) {
  const fetcher = originalUseFetcher(params)
  const confirm = useConfirmModal()
  const navigate = useNavigate()

  useEffect(() => {
    if (fetcher.formMethod === 'POST' && fetcher.state === 'loading') {

      if (fetcher.data?.toast) {
        toast[fetcher.data.toast.appearance](fetcher.data.toast.message, fetcher.data.toast.data)
      }

      if (fetcher.data?.confirmModal) {
        confirm(fetcher.data.confirmModal).then(confirmed => {
          if (confirmed) {
            const formData = fetcher.formData
            formData.set('_confirm', 'true')
            fetcher.submit(formData, {
              method: fetcher.formMethod,
              action: fetcher.formAction,
            })
          }
        })
      }

      if (fetcher.data?.redirect) {
        navigate(fetcher.data.redirect)
      }
    }
  }, [fetcher.data, fetcher.state, fetcher.formMethod])

  return fetcher
}

interface FetcherFormProps extends Omit<OriginalFetcherFormProps, 'children'> {
  /**
   * Function that takes a context object with the fetcher and returns a React node.
   * 
   * @param {object} context
   * @param {object} context.fetcher - The fetcher object returned by the useFetcher hook.
   * @returns {React.ReactNode}
   */
  children: (context: { fetcher: ReturnType<typeof useFetcher> }) => React.ReactNode
}

/**
 * A component that wraps a form in a fetcher form context.
 *
 * @param {FetcherFormProps} props - The props for the FetcherForm component.
 * @returns {JSX.Element} The rendered FetcherForm component.
 */
export const FetcherForm = ({ children, ...rest }: FetcherFormProps) => {
  const fetcher = useFetcher()
  return (
    <fetcher.Form {...rest}>
      {children({ fetcher })}
    </fetcher.Form>
  )
}
