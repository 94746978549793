import React, { PropsWithChildren, useRef, useState, useEffect } from 'react'
import { SoraNavLink } from '@components/link'
import { Button, Icon, cn } from '@design-system'

interface RootProps extends React.HTMLAttributes<HTMLUListElement>, PropsWithChildren { }

function Root({ children, className, ...props }: RootProps) {

  const tabListRef = useRef<HTMLUListElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const checkScrollable = () => {
    if (tabListRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabListRef.current
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1)
    }
  }

  useEffect(() => {
    checkScrollable()
    window.addEventListener('resize', checkScrollable)
    if (tabListRef.current) {
      tabListRef.current.addEventListener('scroll', checkScrollable)
    }
    return () => {
      window.removeEventListener('resize', checkScrollable)
      if (tabListRef.current) {
        tabListRef.current.removeEventListener('scroll', checkScrollable)
      }
    }
  }, [])

  const scrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: -250, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: 250, behavior: 'smooth' })
    }
  }

  return (
    <div className={cn("sticky top-20 z-20 mb-4 border-b dark:border-gray-90 bg-screen-primary flex items-center gap-2", className)}>
      {canScrollLeft && (
        <div className="flex items-center absolute z-10 top-0 bottom-0 -left-2 pl-2 bg-gradient-to-r from-gray-2 via-gray-2 dark:from-gray-100 dark:via-gray-100 w-16">
          <Button onClick={scrollLeft} variant="ghost" size="xs">
            <Icon name="chevron-left" size="xs" />
          </Button>
        </div>
      )}
      <ul ref={tabListRef} className="-ml-2 grow flex items-center gap-4 overflow-x-auto no-scrollbar" role="tablist" {...props}>
        {children}
      </ul>
      {canScrollRight && (
        <div className="flex items-center justify-end absolute z-10 top-0 bottom-0 right-0 bg-gradient-to-l from-gray-2 via-gray-2 dark:from-gray-100 dark:via-gray-100 w-16">
          <Button onClick={scrollRight} variant="ghost" size="xs">
            <Icon name="chevron-right" size="xs" />
          </Button>
        </div>
      )}
    </div >
  )
}

interface TabsItemProps {
  title: string
  to: string
  disabled?: boolean
  notification?: number | boolean
}

/**
 * A tab that represents a section of content.
 */
function Item({ title, to, disabled, notification }: TabsItemProps) {
  const tabRef = useRef<HTMLLIElement>(null)

  const handleClick = () => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }

  return (
    <li ref={tabRef} className="hover:bg-alpha/5 active:bg-alpha/10 rounded-t-md relative">
      <span className="whitespace-nowrap font-bold invisible flex gap-1.5 items-center justify-center py-4 mx-2 border-b-accent aria-[current=page]:border-b-3 aria-[current=page]:font-bold focus-within:ring-accent">
        {title}
        {notification && (
          <span className={cn("rounded-full bg-danger !text-white text-[0.6rem] flex items-center justify-center pt-0.5", typeof notification === 'number' ? "w-4 h-4 min-w-4" : "w-1.5 h-1.5 min-w-1.5")}>{notification}</span>
        )}
      </span>
      <SoraNavLink onClick={handleClick} role="tab" to={to} replace className={cn("whitespace-nowrap absolute text-center inset-0 flex gap-1.5 items-center justify-center py-4 mx-2 border-b-accent aria-[current=page]:border-b-3 aria-[current=page]:font-bold focus-within:ring-accent", disabled && "pointer-events-none text-gray-70 dark:text-gray-70")}>
        {title}
        {notification && (
          <span data-testid="notifcation" className={cn("rounded-full bg-danger !text-white text-[0.6rem] flex items-center justify-center pt-0.5", typeof notification === 'number' ? "w-4 h-4 min-w-4" : "w-1.5 h-1.5 min-w-1.5")}>{notification}</span>
        )}
      </SoraNavLink>
    </li>
  )
}

interface TabsButtonProps {
  title: string
  to: string
}

function ExternalButton({ title, to }: TabsButtonProps) {
  return (
    <Button asChild size="xs" color="soft" className="ml-auto">
      <a role="tab" href={to} target="_blank" rel="noreferrer noopener">
        {title}
        <Icon name="arrow-top-right-filled" size="xs" />
      </a>
    </Button>
  )
}

/**
 * A set of layered sections of content—known as tab panels—that are displayed one at a time.
 * 
 * This Tab component works best with Outlet from react-router.
 */
export const Tabs = Object.assign(Root, { Item, ExternalButton })
