import React, { useState, ReactNode } from 'react'
import { Button, Typography } from '@design-system'

interface ConfirmModalProps {
  /**
   * Control the modal open state
   */
  isOpen: boolean
  /**
   * Callback fired when the component requests to be closed.
   */
  onCancel: any
  /**
   * Callback fired when the confirm button is fired.
   */
  onConfirm: any
  /**
   * Modal Title
   */
  title: string
  /**
   * Modal subtitle
   */
  subtitle?: string
  /**
   * Text label for button with confirm action
   */
  confirmLabel?: string
  /**
   * Text label for button with cancel action
   */
  cancelLabel?: string
  /**
   * Color of the confirm button
   */
  confirmColor?: React.ComponentProps<typeof Button>['color'],
  /**
   * Children element placed between description and buttons
   */
  children?: ReactNode | undefined
}

/**
 * The ConfirmModal provides a easy-to-use Headless Ui Modal to confirm a action
 */
const ConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  title,
  subtitle,
  confirmColor,
  children,
}: ConfirmModalProps) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const handleConfirm = async () => {
    setSubmitting(true)
    await onConfirm()
    setSubmitting(false)
  }
  return (
    <dialog open={isOpen} className="fixed bg-black/75 inset-0 overflow-auto z-60 w-full h-full">
      <div className="absolute top-1/2 left-1/2  w-full max-w-md transform rounded-xl gap-4 bg-white p-6 text-left align-middle shadow-xl transition-all -translate-x-1/2 -translate-y-1/2">
        <header className='grid grid-cols-[1fr_auto]  [grid-template-areas:"title""description"]'>
          <Typography data-testid="title" variant="subheadline" weight="bold" className="grow [grid-area:title]">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body" className="mt-2 [grid-area:description]" color="secondary" data-testid="subtitle">
              {subtitle}
              {children}
            </Typography>
          )}
        </header>
        {children && <div className="mt-4 flex flex-col gap-4">{children}</div>}
        <div className="flex flex-col-reverse md:flex-row md:justify-end gap-4 mt-4">
          <Button loading={isSubmitting} color={confirmColor} onClick={handleConfirm} data-testid="confirm">{confirmLabel}</Button>
          <Button disabled={isSubmitting} onClick={onCancel} variant="outlined" data-testid="cancel">{cancelLabel}</Button>
        </div>
      </div>
    </dialog>
  )
}

ConfirmModal.defaultProps = {
  isOpen: false,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  title: 'Are you sure?',
  isSubmitting: false,
}

ConfirmModal.displayName = 'ConfirmModal'

export { ConfirmModal }

export type { ConfirmModalProps }
