import React, { Fragment, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router'
import { Button, cn } from '@design-system'
import SoraLink from '@components/link'
import { usePagination } from './usePagination'

interface RootProps {
  totalPages: number
  currentPage: number
  className?: string
}

const Root: React.FC<RootProps> = ({ totalPages, currentPage, className }) => {
  const [optimisticPage, setOptimisticPage] = useState(currentPage)

  const paginationRange = usePagination({ currentPage: optimisticPage, totalPages })
  const [searchParams, setSearchParams] = useSearchParams()

  const handlePageChange = (page: number) => {
    setOptimisticPage(page)
    const newParams = new URLSearchParams(searchParams)
    newParams.set('page', page.toString())
    setSearchParams(newParams)
  }
  
  useEffect(() => {
    if (optimisticPage !== currentPage) {
      setOptimisticPage(currentPage)
    }
  }, [currentPage])

  return (
    <div className={cn("flex items-center justify-center gap-1 md:gap-2", className)}>
      {paginationRange.map((page, index) => (
        <Fragment key={page}>
          {page !== paginationRange[index - 1] + 1 && (
            <span className="mx-0.5 md:mx-1"></span>
          )}
          <Button 
            variant={page === optimisticPage ? 'contained' : 'outlined'}
            size="sm"
            className="transition-none md:min-w-14"
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Button>
        </Fragment>
      ))}
    </div>
  )
}

export const Pagination = Object.assign(Root, {})
